/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import cx from 'classnames';
import BurglarAlarmHeader from '../../components/BurglarAlarmHeader';
import Wall from '../Wall';
import Input from '../Input';
import {getEvents, getIncident} from '../../reducers/incident';
import { scrollToBottom } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';
import HelpfulLinks from '../../components/HelpfulLinks';
import { getInput } from '../../reducers/input';
import { getConfirm } from "../../reducers/confirm";
import TimerAndAlarmConfirmation from '../TimerAndAlarmConfirmation';

interface StatusInfo {
  name: string;
  rank: number;
}

const statuses: Record<AlarmStatus, StatusInfo> = {
  active: { name: 'alarm-triggered', rank: 1 },
  called_police: { name: 'called-police', rank: 3 },
  left_queue: { name: 'left-queue', rank: 3 },
  adt_has_notified_police: { name: 'adt-has-notified-police', rank: 3 },
  timed_out: { name: 'alarm-timed-out', rank: 2 },
  disarmed_alarm: { name: 'alarm-canceled', rank: 3 },
  auto_close: { name: 'auto-close', rank: 3 },
};

const eventMapping: { [key: string]: string } = {
  'Incident automatically closed': 'auto_close',
  'CS Incident automatically closed': 'auto_close',
  'CS Operator dispatched authorities': 'adt_has_notified_police',
  'EVENT_CALL_POLICE': 'called_police',
  'CS Incident left queue': 'left_queue',
  'CS Operator accessed incident': 'left_queue'
}
let lastStatus = 'active';

const Chat = (): React.ReactElement => {
  const [t] = useTranslation();
  const { isKeyboardVisible } = useSelector(getInput, shallowEqual);
  const hideContent = isKeyboardVisible && window.innerHeight < 977;
  let { status: originalStatus, contacts = [], site = { address: '' }, events = [], cs_release_at, created_at, agentOpened } = useSelector(getIncident, shallowEqual);
  const { isAlarmProcessed } = useSelector(getConfirm, shallowEqual);
  const contactsList = contacts.filter(c => c.online && Boolean(c.name));
  const [customChatHeight, setCustomChatHeight] = useState(0);
  const [status, setStatus] = useState(originalStatus);
  const date_cs_release_at = new Date(cs_release_at);
  const date_created_at = new Date(created_at);
  const [showTimer, setShowTimer] = useState(false);


  let govUser = date_cs_release_at.getUTCFullYear() === date_created_at.getUTCFullYear() &&
      date_cs_release_at.getUTCMonth() === date_created_at.getUTCMonth() &&
      date_cs_release_at.getUTCDate() === date_created_at.getUTCDate() &&
      date_cs_release_at.getUTCHours() === date_created_at.getUTCHours() &&
      date_cs_release_at.getUTCMinutes() === date_created_at.getUTCMinutes();

  const intervalId = useRef<NodeJS.Timeout | null>(null);

   useEffect(() => {
    // clearInterval when the component is unmounted
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    }
  }, [])

   useEffect(() => {
     const handlePageShow = (event: PageTransitionEvent) => {
      if (event.persisted) {
        window.location.reload();
      }
    };
  window.addEventListener('pageshow', handlePageShow);
  return () => {
    window.removeEventListener('pageshow', handlePageShow);
  };
}, []);

   useEffect(() => {
    intervalId.current = setInterval(() => {
    }, 1000);
    if (govUser && originalStatus == 'timed_out') {
      // @ts-ignore
      originalStatus = lastStatus
    }
    let newStatus = originalStatus;
    //Handle ReLoading of Page
    if ((govUser && originalStatus !== 'disarmed_alarm') ||
      (!govUser && originalStatus !== 'disarmed_alarm')) {
      for (let i = events.length - 1; i >= 0; i--) {
        // If event is in events mapping, ignore the signal sent and received statuses
        if (eventMapping[events[i].type]) {
          // If no rank just use 1 as a default
          const rank = statuses[originalStatus] ? statuses[originalStatus].rank : 1;
          //Compare to see if it's higher rank
          if (statuses[eventMapping[events[i].type]].rank >= rank) {
            // @ts-ignore
            newStatus = eventMapping[events[i].type];
          }
          break;
        }
      }
    }
    // For gov users never show time out status
    if (govUser && newStatus == 'timed_out') {
      newStatus = 'active'
    }
    if (!newStatus) {
      newStatus = originalStatus
    }
    lastStatus = newStatus
    setStatus(newStatus);
    // Determine whether to show Timer
    setShowTimer(newStatus === 'active');
  }, [originalStatus, agentOpened, govUser, events]);

  const alarmTitle = statuses[status] ? statuses[status].name : 'alarm-triggered';

  return (
    <div
      id="chat"
      style={customChatHeight > 0 ? { height: customChatHeight, position: 'inherit' } : {}}
      className={cx('Chat--root', { ['Chat--keyboard']: hideContent })}
    >
      {!hideContent && (
        <BurglarAlarmHeader
          status={status}
          title={t(alarmTitle)}
          address={site && site.address}
          contacts={contactsList}
        />
      )}
      <TimerAndAlarmConfirmation show={showTimer} />
      <Input />
      <Wall />
      {!hideContent && <HelpfulLinks />}
    </div>
  );
};

export default Chat;
