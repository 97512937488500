import React from 'react';
import Timer from '../Timer';
import AlarmConfirmation from '../AlarmConfirmation';

const TimerAndAlarmConfirmation = ({ show }: { show: boolean }) => {
  if (!show) {
    return null;
  }

  return (
    <>
      <AlarmConfirmation />
      <Timer />
    </>
  );
};

export default TimerAndAlarmConfirmation;
